import Link from "next/link";
import { BiChevronDown } from "react-icons/bi";
import styles from "./DropdownMenu.module.scss";

export default function DropdownMenu({
  menuItems,
  title,
  titleSlug,
  containerClass,
}) {
  return (
    <div className={`${styles.container} ${containerClass}`}>
      <Link href={titleSlug} className={`${styles.titleLink}`} prefetch={false}>
        {title}
        <BiChevronDown />
      </Link>

      <div className={`${styles.dropdown}`}>
        <div className={`${styles.spacer}`}></div>
        <ul className={`${styles.menuItems}`}>
          {menuItems.map((item) => (
            <li className={`${styles.menuItem}`} key={item.slug}>
              <Link href={item.slug} className={`${styles.menuItemLink}`}>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
